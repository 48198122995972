<template>
  <div class="main cf">
    <h2 class="h2">联系我们</h2>
    <div class="top_main">
      <div class="main_left">
      <img src="@/assets/images/03_zsfr.jpg" class="img"/>
    </div>
    <div ref="map" id="map" style="width: 910px; height: 400px"></div>
    </div>
   <center>
     <div class="main_right">
        联系人：陈建<br />
        咨询热线：13739421225<br />
        联系邮箱：13739421225@163.com<br />
        公司地址：四川省成都市武侯区商鼎国际
      </div>
   </center>
  </div>
</template>

<script>
export default {
  data() {
    return {
      map: null,
      point: null,
      marker: null,
      local: null,
    };
  },
  mounted() {
    this.map = new window.BMapGL.Map(this.$refs.map);
    this.point = new window.BMapGL.Point(104.116093, 30.650977);
    this.map.centerAndZoom(this.point, 17);
    this.map.enableScrollWheelZoom(true);
    //添加一个点
    this.marker = new window.BMapGL.Marker(this.point); // 创建标注
    this.map.addOverlay(this.marker); // 将标注添加到地图中
    this.local = new window.BMapGL.LocalSearch(this.map, {
      renderOptions: { map: this.map },
    });
  },
  watch: {
    keyword: {
      handler() {
        if (this.keyword === "") {
          this.local.clearResults();
          this.map.centerAndZoom(this.point, 15);
        } else {
          this.local.search(this.keyword);
        }
      },
    },
  },
};
</script>

<style scoped>
.main {
  margin-bottom: 16px;
  padding: 0 5%;
}
.top_main{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/* 左边 */
.main_left {
  float: left;
  width: 261px;
  margin-right: 28px;
}
.main_left a {
  font-size: 12px;
  width: 261px;
  height: 48px;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  background: #f2f2f2;
  margin-bottom: 2px;
}
.main_left img {
  width: 261px;
  margin-top: 20px;
}
/* 右边 */
.main_right {
  box-sizing: border-box;
  width: 910px;
  height: 213px;
  border: 3px solid #eee;
  padding: 22px;
  line-height: 2em;
  font-size: 14px;
  color: #818388;
  margin-top: 30px;
}

 @media screen and (max-width:500px) {
  .main_left {
    display: none !important;
  }
  .main_right{
    width: 100% !important;
  }
 }
</style>
